<template>
	<div class="resigter">
		<div class="resigter_wrap">
			<div class="resigter_box">
				<section class="resigter_card">
					<div class="resigter_title">青海省工业小微企业融资服务平台</div>

					<div class="resigter_from">
						<div class="resigter_from_left">
							<img src="../../assets/image/11.png" class="resigter_pic">
						</div>

						<div class="resigter_from_rig">
							<div class="resigter_from_top">
								<!-- <el-radio v-model="radio" :label="1">企业注册</el-radio> -->
                                <el-radio v-model="radio" :label="1">金融机构注册</el-radio>
							</div>


							<div class="resigter_user">
								<el-form label-width="43px" :model="ruleForm" :rules="rules" ref="ruleForm">
								  <el-form-item prop='name'>
								  	 <div class="resigter_user_line">
								  	 	<img src="../../assets/image/9.png" class="resigter_icon" />
								        <el-input v-model.number="ruleForm.name" placeholder='请输入手机号码'></el-input>
								  	 </div>
								  </el-form-item>

								  <el-form-item prop='type'>
								  	 <div class="resigter_user_line">
								  	 	<img src="../../assets/image/10.png" class="resigter_icon" />
								  	 	<el-input v-model="ruleForm.type" placeholder='请输入验证码' class='resigter_inp'></el-input>
								  	 	<div class="resigter_user_code" @click='$throttle(sendCode)' v-if='flag'>发送验证码</div>
								  	 	<div class="resigter_user_code resigter_wait" v-else>{{num}}s 后重新发送</div>
								  	 </div>
								  </el-form-item>

								  <el-form-item prop='region'>
								  	<div class="resigter_user_line">
								  	 	<img src="../../assets/image/12.png" class="resigter_icon" />
								  	 	<el-input v-model="ruleForm.region" placeholder='请输入6-16位数字和大小写字母组合' type="password"></el-input>
								  	 </div>
								  </el-form-item>

								  <el-form-item prop='pass'>
								  	<div class="resigter_user_line">
								  	 	<img src="../../assets/image/12.png" class="resigter_icon" />
								  	 	<el-input v-model="ruleForm.pass" placeholder='请输入登录密码' type="password"></el-input>
								  	 </div>
								  </el-form-item>

								  <div class="resigter_line">
								  	  <el-checkbox v-model="checked">注册表示您已阅读并同意 <span>《用户服务协议》</span></el-checkbox>
								  </div>

								  <div class="resigter_submit" @click='$throttle(setAdmin)'>注册</div>

								  <div class="is_resigter">我已注册，直接  <span @click='toLogin'>登录>></span></div>
								</el-form>
							</div>
						</div>
					</div>
				</section>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			const checkName = (rule, value, callback) => {
		        if (!value) {
		          return callback(new Error('手机号码不能为空'))
		        }

		        setTimeout(() => {
		          if (!Number.isInteger(value)) {
		            callback(new Error('请输入数字值'))
		          } else {
		            if (!this.$regPhone.test(value)) {
		              callback(new Error('手机号码格式错误'))
		            } else {
		              callback()
		            }
		          }
		        }, 500)
		    };
		    const checkRegion = (rule, value, callback) => {
		        if (!value) {
		          return callback(new Error('密码不能为空'))
		        }

		        setTimeout(() => {
		        	
		            if (!this.$regPass.test(value)) {
		              callback(new Error('请输入6-16位数字和大小写字母组合'))
		            } else {
		              callback()
		            }
		        }, 500)
		    };
			const validatePass = (rule, value, callback) => {
				
		        if (value === '') {
		          callback(new Error('请再次输入密码'))
		        } else if (value !== this.ruleForm.region) {
		          callback(new Error('两次输入密码不一致!'))
		        } else {
		          callback()
		        }
		    };
			return {
				radio: 1,
				flag: true,
				num: 60,
				checked: false,
		        ruleForm: {
		          name: '',
		          region: '',
		          pass: '',
		          type: ''
		        },
				// 验证规则
		        rules: {
		            name: [
		              { required: true, validator: checkName, trigger: 'blur' }
		            ],
		            region: [
		              { min: 6, max: 16, required: true, validator: checkRegion, trigger: 'blur' }
		            ],
		            pass: [
		              { required: true, validator: validatePass, trigger: 'blur'}
		            ],
		            type: [
		              { required: true, message: '验证码不能为空', trigger: 'blur'},
		            ]
		        },
		        code: '',
		        timer: ''
			}
		},
		methods: {
			// 发送验证码
			sendCode() {
				if(this.ruleForm.name == '') {
					this.$message({
						message: '手机号码不能为空',
						type: 'error'
					})
					return false
				}

				if(!this.$regPhone.test(this.ruleForm.name)) {
					this.$message({
						message: '手机号码格式错误',
						type: 'error'
					})
					return false
				}

				let data = {phone: this.ruleForm.name}

				this.$get('/get_code', data).then(res => {
					
					if(res.data.status == 1) {
						if(this.timer) {
							clearInterval(this.timer)
						}

						this.flag = false
						this.timer = setInterval(() => {
							this.num--

							if(this.num == 0) {
								clearInterval(this.timer)

								this.flag = true
								this.num = 60
							}
						}, 1000)
					}else {
						this.$message({
							message: res.data.msg,
							type: 'error'
						})
					}
				})
			},
			toLogin() {
				this.$router.push('/login')
			},
			// 注册
			setAdmin() {
				
				if(this.ruleForm.name == '') {
					this.$message({
		        		message: '手机号码不能为空',
		        		type: 'error'
		        	})
		        	return false
				}

		        if(!this.$regPhone.test(this.ruleForm.name)) {
		        	this.$message({
		        		message: '手机号码格式错误',
		        		type: 'error'
		        	})
		        	return false
		        }

		        if(this.ruleForm.type == '') {
		        	this.$message({
		        		message: '验证码不能为空',
		        		type: 'error'
		        	})
		        	return false
		        }

		        if(!this.$regPass.test(this.ruleForm.region)) {
		        	this.$message({
		        		message: '请输入6-16位数字和大小写字母组合',
		        		type: 'error'
		        	})
		        	return false
		        }

		        if(this.ruleForm.region != this.ruleForm.pass) {
		        	this.$message({
		        		message: '两次输入密码不一致',
		        		type: 'error'
		        	})
		        	return false
		        }

		        if(!this.checked) {
		        	this.$message({
		        		message: '请勾选用户服务协议',
		        		type: 'error'
		        	})
		        	return false
		        }


		        let data = {
		        	mobile: this.ruleForm.name,
		        	password: this.ruleForm.region,
		        	repassword: this.ruleForm.pass,
		        	code: this.ruleForm.type,
		        	type: this.radio
		        }

		        this.$post('/register', data).then(res => {
		        	let type = res.data.status == 1 ? 'success' : 'error'

					this.$message({
						message: res.data.msg,
						type,
						duration: 1000
					})

					if(res.data.status == 1) {
						setTimeout(() => {
							this.$router.replace('/login')
						}, 1000)
					}
		        })
			}
		}
	}
</script>

<style scoped>
	@import './resigter.css';
</style>